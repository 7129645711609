@import url(https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css);
@font-face {
  font-family: 'Sukhumvit Set';
  src: url(/static/media/SukhumvitSet-Bold.f7fab7ec.woff2) format('woff2'),
      url(/static/media/SukhumvitSet-Bold.b5fe0d62.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sukhumvit Set';
  src: url(/static/media/SukhumvitSet-Medium.46c04f76.woff2) format('woff2'),
      url(/static/media/SukhumvitSet-Medium.4e82cefd.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sukhumvit Set';
  src: url(/static/media/SukhumvitSet-Light.7e715e1e.woff2) format('woff2'),
      url(/static/media/SukhumvitSet-Light.71d043fe.woff) format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sukhumvit Set';
  src: url(/static/media/SukhumvitSet-Text.0589005b.woff2) format('woff2'),
      url(/static/media/SukhumvitSet-Text.4fdb800a.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sukhumvit Set';
  src: url(/static/media/SukhumvitSet-Thin.c793703e.woff2) format('woff2'),
      url(/static/media/SukhumvitSet-Thin.93a0b3ac.woff) format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sukhumvit Set';
  src: url(/static/media/SukhumvitSet-SemiBold.d28cb807.woff2) format('woff2'),
      url(/static/media/SukhumvitSet-SemiBold.ae1e5204.woff) format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'Sukhumvit Set';
  /* margin: 0; */
  scroll-behavior: smooth;
}

.datepicker {
  width: 100%;
}

